
<template>
  <div>
    <div class="active-type">
      <el-menu
        :default-active="1"
        active-text-color="#2d6cfc"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item
          v-for="(item, index) in activedTypeArr"
          :index="index"
          :key="index"
        >
          {{ item.label }}({{ item.num }})</el-menu-item
        >
      </el-menu>
    </div>
    <div class="filter">
      <el-select v-model="filterValue" placeholder="请选择">
        <el-option
          v-for="item in filterOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary">
        <i class="el-icon-plus"></i>
        添加过滤器
      </el-button>
    </div>
    <!-- {{chatList}} -->
    <div class="all-content">
      <el-table :data="cusList" style="width: 100%" border>
        <template v-for="(item) in labelDataSt">
          <el-table-column
            v-if="item.label == 'name'"
            :key="item.label"
            :prop="item.prop"
            :label="item.name"
          >
          </el-table-column>
          <!-- ACTIONS -->
          <el-table-column
            v-else-if="item.label == 'actions'"
            :key="item.label"
            :label="item.name"
          >
            <template slot-scope="scope">
              <div class="btn-group">
                <!-- start caht -->
                <el-button
                  type="primary"
                  @click="btnClick(scope.row, scope.$index)"
                  v-if="scope.row.state !== 'left'"
                  :class="{
                    'go-to-chat': scope.row.state == 'chatting' || scope.row.state == 'banned',
                    'supervised-chat':
                      scope.row.state == 'chatOther' ||
                      scope.row.state == 'supervising',
                    'queued-chat': scope.row.state == 'queued',
                  }"
                >
                {{chatActions[scope.row.state]}}
                  <!-- {{
                    scope.row.state == "browsing" ||
                    scope.row.state == "chat closed" ||
                    scope.row.state == "invited"
                      ? "开始聊天"
                      : ""
                  }}
                  {{ scope.row.state == "chatting"   ? "去聊天" : "" }}
                  {{ scope.row.state == "banned"   ? "禁止聊天" : "" }}
                  {{ scope.row.state == "chatOther" ? "监督聊天" : "" }}
                  {{ scope.row.state == "supervising" ? "去聊天" : "" }}
                  {{ scope.row.state == "queued" ? "从队列中选择" : "" }} -->
                </el-button>
                <!-- 按钮右侧小按钮 -->
                <el-button
                  type="primary"
                  :class="{
                    'right-btn': true,
                    'supervised-chat':
                      scope.row.state == 'chatOther' ||
                      scope.row.state == 'supervising',
                  }"
                  v-if="
                    scope.row.state == 'browsing' ||
                    scope.row.state == 'supervising' ||
                    scope.row.state == 'chat closed' ||
                    scope.row.state == 'invited'
                  "
                  @click="smallBtnClick(scope.row)"
                >
                  {{
                    scope.row.state == "browsing" ||
                    scope.row.state == "chat closed" ||
                    scope.row.state == "invited"
                      ? "笔"
                      : ""
                  }}
                  {{ scope.row.state == "supervising" ? "删" : "" }}
                </el-button>
              </div>
            </template>
          </el-table-column>
          <!-- ACTIVITY -->
          <el-table-column
            v-else-if="item.label == 'activity'"
            :key="item.label"
            :prop="item.prop"
            :label="item.name"
          >
            <template slot-scope="scope">
              <div class="activity">
                <span
                  :class="{
                    bot: true,
                    'left-bot': scope.row.state == 'left',
                    'queued-bot': scope.row.state == 'queued',
                  }"
                ></span>
                <span
                  :class="{
                    chatting: scope.row.state == 'chatting',
                    left: scope.row.state == 'left',
                    queued: scope.row.state == 'queued',
                  }"
                  >{{
                    scope.row.state != "chat closed"|| scope.row.state != "banned"  ||!( scope.row.state == "chatting" && scope.row.followed == false )
                      ?chatActivity[scope.row.state]
                      : "浏览"
                  }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :key="item.label"
            :prop="item.prop"
            :label="item.name"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>
<script>
import * as chatApi from "@/views/liveChat/chatApi.js";
export default {
  name: "AllChat",
  props: {
    cusListProp: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chatListProp: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      chatActions: {
        "chatting":"去聊天",
        "supervising":"去聊天",
        "chat closed":"开始聊天",
        "browsing":"开始聊天",
        "invited":"开始聊天",
        "banned":"禁止聊天",
        "chatOther":"监督聊天",
        "queued":"从队列中选择"
      },
      chatActivity: {
        "chatting":"聊天中",
        "supervising":"监督聊天",
        "chat closed":"浏览",
        "browsing":"浏览",
        "invited":"受邀请",
        "banned":"浏览",
        "chatOther":"聊天中",
        "queued":"排队中"
      },
      labelDataSt: [
        {
          label: "name",
          name:"姓名",
          prop: "name",
        },
        {
          label: "email",
          name:"邮件",
          prop: "email",
        },
        {
          label: "actions",
          name:"动作",
          prop: "state",
        },
        {
          label: "activity",
          name:"状态",
          prop: "state",
        },
      ], //表头
      cusList: this.cusListProp, //聊天列表
      chatList: this.chatListProp,
      activedTypeArr: [
        {
          label: "所有客户",
          num: 0,
          type: "all",
        },
        {
          label: "聊天",
          num: 0,
          type: "chatting",
        },
        {
          label: "监督",
          num: 0,
          type: "supervised",
        },
        {
          label: "排队",
          num: 0,
          type: "queue",
        },
        {
          label: "等待回复",
          num: 0,
          type: "wait",
        },
        {
          label: "受邀",
          num: 0,
          type: "invted",
        },
        {
          label: "浏览",
          num: 0,
          type: "browsing",
        },
      ], // 菜单类型
      filterValue: "any", //被选中过滤器
      filterOptions: [
        {
          label: "匹配所有",
          value: "all",
        },
        {
          label: "匹配一些",
          value: "any",
        },
      ],
      agentInfo: JSON.parse(localStorage.getItem("agentInfo")),
    };
  },
  methods: {
    handleSelect() {},
    // actions 按钮点击事件
    async btnClick(row, index) {
      // 没有chats start_chat
      // 有chats  继续聊天 resume_chat  /  和别人聊天  变成监督
      // 开始聊天 start chat
      if (
        row.state == "browsing" ||
        row.state == "invited" ||
        row.state == "chat closed"
      ) {
        if (row?.chats) {
          // 有chats
          let param = {
            id: row.chats[0].chat_id,
          };
          //
          const data = await chatApi.resumeChat(param);
          if (data?.error) {
            // //alert("报错", data);
            return;
          }else{
            this.$set(this.cusList[index],'state','chatting')
          }
        } else {
          const param = {
            chat: {
              users: [
                {
                  id: row.id,
                  type: row.type,
                },
              ],
            },
          };
          chatApi.start_chat(param);
        }
      }
      // 去聊天 go to chat
      if (row.state == "chatting") {
        this.$emit("openChat", row.chats[0].chat_id, row.id,'chatting',true);
      }
      if (row.state == "supervising") {
        this.$emit("openChat", row.chats[0].chat_id, row.id, "supervising",true);
      }
      // pick from queue
      if(row.state == "queued"){
        const param = {
          id:row.chats[0].chat_id,
          target:{
            type:'agent',
            ids:[this.agentInfo.id]
          }
        }
        await chatApi.transfer_chat(param)
      }
      // 监督聊天 （修改会话属性）
      if (row.state == "chatOther") {
        // 保存之前的supervising agent_ids
        let agent_ids = "";
        if (row.properties?.supervising?.agents_ids) {
          agent_ids =
            this.agentInfo.id + "," + row.properties?.supervising?.agent_ids;
        } else {
          agent_ids = this.agentInfo.id;
        }

        let param = {
          id: row.chats[0].chat_id,
          properties: {
            supervising: { agent_ids: agent_ids },
          },
        };

        const data = await chatApi.chat_properties_updated(param);
        const follow = await chatApi.follow_chat({id:row.chats[0].chat_id})
        if (data?.error ||follow?.error) {
          return;
        }
        this.$set(this.cusList[index], "state", "supervising");
      }
    },
    // actions 右侧快捷小按钮点击事件
    async smallBtnClick(row) {
      // 关闭监督
      if (row.state == "supervising") {
        const chatInfo = await chatApi.getChat(row.chats[0].chat_id);
        if (chatInfo?.error) {
          //alert("报错", chatInfo);
          return;
        }
        // 保存之前的supervising agent_ids
        let regExp = new RegExp(`${this.agentInfo.id},?`,'g')
        const param = {
          id: row.chats[0].chat_id,
          properties: {
            supervising: {
              agent_ids: chatInfo.properties.supervising.agent_ids.replaceAll(
               regExp,
                ""
              ),
            },
          }, 
        };
        const data = await chatApi.chat_properties_updated(param);
        if (data?.error) {
          return;
        }
        this.addSupervisedToCusList();
      } 
    },
    // 给客户列表列表添加监督状态（supervise chat） (go to start ???)
    // 1.监督肯定有会话 chats 2.在和别人通话
    // 需要优化  不能一直请求接口
    addSupervisedToCusList() {
      this.cusList.forEach(async (item) => {
        if (item?.chats) {
          let chat_id = item.chats[0].chat_id;
          // 获取每一个会话的信息
          const chatInfo = await chatApi.getChat(chat_id);
          if (chatInfo?.error) {
            return;
          }
          let presentlength = chatInfo.users.filter((item)=>{
            return item.present
          }).length
           // 禁止客户
          if(presentlength<2){
            if(item.state == 'chatting'){
              this.$set(item, "state", "banned");
            }
            return 
          }
          chatInfo.users.forEach((sItem) => {
              if (
              sItem.type == "agent" &&
              sItem.present == false &&
              sItem.id == this.agentInfo.id &&
              (item.state == "chatting" || item.state == "supervising") &&
              !chatInfo.properties?.supervising?.agent_ids.includes(
                this.agentInfo.id
              )
            ) {
              this.$set(item, "state", "chatOther");
              return
            }
            // 和别人通话已监听
            if (
              item.state == "chatting" &&
              chatInfo.properties?.supervising?.agent_ids.includes(
                this.agentInfo.id
              )
            ) {
              this.$set(item, "state", "supervising");
              return
            }
          }); 
        }
      });
    },
   
  },
  mounted() {
    this.$bus.$on("chat_properties_updated", () => {
      this.addSupervisedToCusList();
    });
  },
  watch: {
    cusListProp: {
      handler(newValue) {
        this.cusList = newValue;
        this.addSupervisedToCusList();
      },
      deep: true,
      immediate: true,
    },
  },
  destroyed() {
    this.$bus.$off("chat_properties_updated");
  },
};
</script>
<style lang="scss" scoped>
@mixin blue-border-btn {
  color: #2d6cfc;
  background: #fff;
  border: 1px solid #2d6cfc;
}
::v-deep .el-table--border td,
th {
  border-right: none;
}
::v-deep .el-table .cell {
  white-space: nowrap;
}
.filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #ccc;
  padding-left: 10px;
  button {
    @include blue-border-btn;
    margin: 0px 10px;
  }
}
.all-content {
  .btn-group {
    display: flex;
    button {
      flex: 1;
      max-width: 200px;
      height: 28px;
      border-radius: 4px;
      line-height: 28px;
      font-size: 14px;
      font-weight: bold;
      padding: 0px;
      text-align: center;
    }
    .right-btn {
      max-width: 30px;
    }
    .go-to-chat {
      @include blue-border-btn;

      &:hover {
        @include blue-border-btn;
      }
    }
    .supervised-chat {
      font-weight: bold;
      border-color: rgb(66, 77, 87);
      background-color: rgb(66, 77, 87);
      color: rgb(255, 255, 255);
    }
    .queued-chat {
      font-weight: bold;
      border-color: rgb(44, 176, 106);
      background-color: rgb(44, 176, 106);
      color: rgb(255, 255, 255);
    }
  }
  .activity {
    color: rgb(66, 77, 87);
    span {
      display: inline-block;
    }
    .bot {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: rgb(70, 202, 132);
      margin-right: 10px;
    }
    .left-bot {
      background: rgba(66, 77, 87, 0.3);
    }
    .queued-bot {
      background: red;
    }
    .queued {
      color: red;
    }
    .left {
      color: rgba(66, 77, 87, 0.3);
    }
    .chatting {
      color: rgb(76, 183, 126);
    }
  }
}
</style>