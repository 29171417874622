var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"active-type"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":1,"active-text-color":"#2d6cfc","mode":"horizontal"},on:{"select":_vm.handleSelect}},_vm._l((_vm.activedTypeArr),function(item,index){return _c('el-menu-item',{key:index,attrs:{"index":index}},[_vm._v(" "+_vm._s(item.label)+"("+_vm._s(item.num)+")")])}),1)],1),_c('div',{staticClass:"filter"},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}},_vm._l((_vm.filterOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-button',{attrs:{"type":"primary"}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" 添加过滤器 ")])],1),_c('div',{staticClass:"all-content"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.cusList,"border":""}},[_vm._l((_vm.labelDataSt),function(item){return [(item.label == 'name')?_c('el-table-column',{key:item.label,attrs:{"prop":item.prop,"label":item.name}}):(item.label == 'actions')?_c('el-table-column',{key:item.label,attrs:{"label":item.name},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"btn-group"},[(scope.row.state !== 'left')?_c('el-button',{class:{
                  'go-to-chat': scope.row.state == 'chatting' || scope.row.state == 'banned',
                  'supervised-chat':
                    scope.row.state == 'chatOther' ||
                    scope.row.state == 'supervising',
                  'queued-chat': scope.row.state == 'queued',
                },attrs:{"type":"primary"},on:{"click":function($event){return _vm.btnClick(scope.row, scope.$index)}}},[_vm._v(" "+_vm._s(_vm.chatActions[scope.row.state])+" ")]):_vm._e(),(
                  scope.row.state == 'browsing' ||
                  scope.row.state == 'supervising' ||
                  scope.row.state == 'chat closed' ||
                  scope.row.state == 'invited'
                )?_c('el-button',{class:{
                  'right-btn': true,
                  'supervised-chat':
                    scope.row.state == 'chatOther' ||
                    scope.row.state == 'supervising',
                },attrs:{"type":"primary"},on:{"click":function($event){return _vm.smallBtnClick(scope.row)}}},[_vm._v(" "+_vm._s(scope.row.state == "browsing" || scope.row.state == "chat closed" || scope.row.state == "invited" ? "笔" : "")+" "+_vm._s(scope.row.state == "supervising" ? "删" : "")+" ")]):_vm._e()],1)]}}],null,true)}):(item.label == 'activity')?_c('el-table-column',{key:item.label,attrs:{"prop":item.prop,"label":item.name},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"activity"},[_c('span',{class:{
                  bot: true,
                  'left-bot': scope.row.state == 'left',
                  'queued-bot': scope.row.state == 'queued',
                }}),_c('span',{class:{
                  chatting: scope.row.state == 'chatting',
                  left: scope.row.state == 'left',
                  queued: scope.row.state == 'queued',
                }},[_vm._v(_vm._s(scope.row.state != "chat closed"|| scope.row.state != "banned" ||!( scope.row.state == "chatting" && scope.row.followed == false ) ?_vm.chatActivity[scope.row.state] : "浏览"))])])]}}],null,true)}):_c('el-table-column',{key:item.label,attrs:{"prop":item.prop,"label":item.name}})]})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }