/*
 * @Author: shi tao
 * @Date: 2022-03-03 14:54:59
 * @LastEditors: shi tao
 * @lastEditTime: Do not edit
 * @Description: 
 * @FilePath: \lightning-web\src\views\liveChat\chatApi.js
 */
import axios from 'axios'
import SDK from "@livechat/chat-sdk";
// 开启debug模式
export const ChatSDK = new SDK({ debug: true, apiVersion: "3.3" });

export const createAgent = () => {
  return axios.post('https://api.livechatinc.com/v3.4/configuration/action/create_agent', {
    id: "1603663611@qq.com",
    name: "Agent Smith"
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Basic NzFjZTkzZTctYmRlMC00MjdhLTg4NzEtNmEzOGFhODc5NzQxOmRhbDpyWXQ0Ym51RW95Mm1IZzZST1AzeTY0VDd0MW8='
    }
  })
}

// Custom methods created with `ChatSDK.methodFactory`:

/**
 * Returns list of last 10 archived chats
 * Based on: https://developers.livechat.com/docs/messaging/agent-chat-api/rtm-reference/#get-archives
 */
export const getArchives = () =>
  ChatSDK.methodFactory({
    action: "list_archives",
    payload: {
      pagination: {
        limit: 10
      }
    }
  });

/**
* Returns threads that the current Agent has access to in a given chat.
* @param {string} chat_id 
* @param {string[]} thread_ids 
* Based on: https://developers.livechat.com/docs/messaging/agent-chat-api/rtm-reference/#get-chat-threads
*/
export const getChat = (chat_id, thread_id) =>
  ChatSDK.methodFactory({
    action: "get_chat",
    payload: { chat_id, thread_id }
  });

/**
* Returns threads that the current Agent has access to in a given chat.
* @param {string} chat_id 
* @param {string[]} thread_ids 
* Based on: https://developers.livechat.com/docs/messaging/agent-chat-api/rtm-reference/#get-chat-threads
*/
export const getChatsList = () =>
  ChatSDK.methodFactory({
    action: "list_chats",
  });

/**
 * 获取在线坐席列表
 * @param {string} chat_id 会话ID
 * @returns 对该对话有权限的在线坐席列表
 */
export const list_agents_for_transfer = (chat_id) =>
  ChatSDK.methodFactory({
    action: "list_agents_for_transfer",
    payload: { chat_id }
  })
/**
 * 将用户添加到会话中
 * @param {string} chat_id 会话ID
 * @param {string} user_id 用户ID
 * @param {string} user_type  用户类型 agent或customer
 * @param {string} visibility 消息可见权限 all或agents
 * @returns success  是否添加成功
 */
export const addUserToChat = (chat_id, user_id, user_type, visibility) =>
  ChatSDK.methodFactory({
    action: 'add_user_to_chat',
    payload: {
      chat_id: chat_id,
      user_id: user_id,
      user_type: user_type,
      visibility: visibility,
      ignore_requester_presence: true
    }
  })
/**
 * 创建代理
 * agentApi
 */
export const agentApi = (action, params) =>
  axios.post(
    `https://api.livechatinc.com/v3.4/configuration/action/${action}`,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic YWMwMWEwMmMtY2VmMi00OTJlLTg4YmUtYWRmODZlY2Q0ZDM1OmRhbDpmaVd5VEI5RmVyQk4yZGNCSDJGWjUyN1lZMkk='
      }
    }
  )
/**
  * 回去客户个人信息  customer info
  * @param {string} cus_id  客户id
  * @returns object 客户信息对象
  */
export const getCustomer = (cus_id) =>
  ChatSDK.methodFactory({
    action: 'get_customer',
    payload: {
      id: cus_id
    }
  })
/**
 * 继续聊天
 * @param {object} chat
 */
export const resumeChat = (chat) => {
  ChatSDK.methodFactory({
    action: 'resume_chat',
    payload: {
      chat
    }
  })
}
/**
 * 修改聊天属性
 * @param {Object} param 
 */
export const chat_properties_updated = (param) => {
  ChatSDK.methodFactory({
    action: 'update_chat_properties',
    payload: {
      ...param
    }
  })
}
/**
 * 开始聊天
 * @param {Object} param 
 */
export const start_chat = (param) => {
  ChatSDK.methodFactory({
    action: 'start_chat',
    payload: {
      ...param
    }
  })
}
/**
 * 关注聊天
 * @param {Object} param 
 */
export const follow_chat = (param) => {
  ChatSDK.methodFactory({
    action: 'follow_chat',
    payload: {
      ...param
    }
  })
}
/**
 * 取消关注聊天
 * @param {Object} param 
 */
export const unfollow_chat = (param) => {
  ChatSDK.methodFactory({
    action: 'unfollow_chat',
    payload: {
      ...param
    }
  })
}
/**
* 关闭聊天
* @param {Object} param 
*/
export const deactivate_chat = (param) => {
  ChatSDK.methodFactory({
    action: 'deactivate_chat',
    payload: {
      ...param
    }
  })
}
/**
 * 转移聊天
 * @param {Object} param 
 */
export const transfer_chat = (param) => {
  ChatSDK.methodFactory({
    action: 'transfer_chat',
    payload: {
      ...param
    }
  })
}
/**
 * 获取某个会话的线程列表
 * @param {Object} param 
 */
export const list_threads = (param) => {
  ChatSDK.methodFactory({
    action: 'list_threads',
    payload: {
      ...param
    }
  })
}
/**
  * 在特定时间段内禁止客户
  * @param {Object} param 
  */
export const ban_customer = (param) => {
  ChatSDK.methodFactory({
    action: 'ban_customer',
    payload: {
      ...param
    }
  })
}
/**
 * 退出登录
 */
export const logout = () => {
  // ChatSDK.methodFactory({
  //   action: 'logout',
  // })
  // return axios.post('wss://api.livechatinc.com/v3.4/agent/rtm/ws', {

  // })
  let webSocketObj = new WebSocket('wss://api.livechatinc.com/v3.3/agent/rtm/ws')
  webSocketObj.addEventListener('open', function () {
    webSocketObj.send({
      "action": "logout",
      "payload": {}
    })
});

  // webSocketObj.send({
  //   "action": "logout",
  //   "payload": {}
  // })
}

