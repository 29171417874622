
<template>
  <div style="height: calc(100% - 50px)">
    <send-code
      v-if="!isInstall && isShow"
      :licenseID="licenseID"
      @nextStep="isInstall = true"
    ></send-code>
    <div class="chat-main" v-if="isInstall && isShow">
      <!-- 会话类型列表 -->
      <div class="chat-type-expand" v-show="!chatTypeExpand">
        <div class="chat-top type-top">
          <span @click="chatTypeExpand = true">
            <svg aria-hidden="true" class="icon i">
              <use href="#icon-zhankai"></use>
            </svg>
          </span>
        </div>
      </div>
      <div class="chat-type" v-show="chatTypeExpand">
        <div class="chat-top type-top">
          <p>会话</p>
          <p>
            <span>
              <svg aria-hidden="true" class="icon i search">
                <use href="#icon-sousuo2"></use>
              </svg>
            </span>
            <span @click="chatTypeExpand = false">
              <svg aria-hidden="true" class="icon i">
                <use href="#icon-zhankai"></use>
              </svg>
            </span>
          </p>
        </div>
        <div class="type-list">
          <p @click="typeIndex = 0" :class="{ 'chat-checked': typeIndex == 0 }">
            <span>
              <svg aria-hidden="true" class="icon i">
                <use href="#icon-xiaoxi"></use></svg></span
            ><span>我的聊天</span><span>{{ chattingLength }}</span>
          </p>
          <p @click="typeIndex = 1" :class="{ 'chat-checked': typeIndex == 1 }">
            <span>
              <svg aria-hidden="true" class="icon i">
                <use href="#icon-duihua"></use></svg></span
            ><span>所有聊天</span><span>{{ cusList.length }}</span>
          </p>
          <!-- 接受聊天 -->
          <div class="accept-chat">
            <div>
              <span>接受聊天</span><el-switch v-model="acceptChat"> </el-switch>
            </div>
            <el-button @click.prevent="logOut">退出登录</el-button>
          </div>
        </div>
      </div>
      <div v-show="typeIndex == 0" class="my-chat">
        <!-- 会话列表 -->
        <div class="chat-list">
          <div class="chat-top chat-list-top">
            <p>我的聊天</p>
          </div>
          <el-collapse
            v-show="this.chatList.length !== 0"
            v-model="activeNames"
            @change="handleChangeCollapse"
          >
            <!-- 聊天列表 -->
            <el-collapse-item :title="`My chat(${chattingLength})`" name="1">
              <chat-info-thumb
                v-for="(item, index) in chattingList"
                :key="item.id"
                :chatProp="item"
                :chatIDProp="chatID"
                :index="index"
                @deleteChat="deleteChat"
                chatTypeProp="chatting"
                @openChat="openChat"
              ></chat-info-thumb>
            </el-collapse-item>
            <!-- 监督列表 -->
            <el-collapse-item
              :title="`Supervised(${chatSupervisingList.length})`"
              name="2"
              v-show="chatSupervisingList.length > 0"
            >
              <chat-info-thumb
                v-for="(item, index) in chatSupervisingList"
                :key="item.id"
                :index="index"
                :chatProp="item"
                :chatIDProp="chatID"
                @deleteChat="deleteChat"
                chatTypeProp="supervising"
                @openChat="openChat"
              ></chat-info-thumb>
            </el-collapse-item>
            <!-- 排队列表 -->
            <el-collapse-item
              :title="`Queued(${chatQueuedList.length})`"
              name="3"
              v-show="chatQueuedList.length > 0"
            >
              <chat-info-thumb
                v-for="(item, index) in chatQueuedList"
                :key="item.id"
                :index="index"
                :chatProp="item"
                :chatIDProp="chatID"
                @deleteChat="deleteChat"
                chatTypeProp="queued"
                @openChat="openChat"
              ></chat-info-thumb>
            </el-collapse-item>
          </el-collapse>
          <el-skeleton
            v-show="this.chatList.length == 0"
            style="padding: 5px 20px"
          >
            <template slot="template">
              <div
                style="
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                "
                v-for="(item, index) in 5"
                :key="index"
              >
                <el-skeleton-item
                  variant="circle "
                  style="width: 50px; height: 50px"
                />
                <div style="padding: 14px; width: 80%;}">
                  <el-skeleton-item variant="p" style="width: 100%" />
                  <el-skeleton-item variant="p" style="width: 100%" />
                </div>
              </div>
            </template>
          </el-skeleton>
        </div>
        <!-- 聊天内容 -->
        <div class="chat-content">
          <div class="chat-top">
            <!-- 用户名称 -->
            <div>{{ cusInfo.name }}</div>
            <!-- 操作 -->
            <div class="more">
              <i
                class="el-icon-more"
                @click="isShowMoreList = !isShowMoreList"
              ></i>
              <div class="more-list" v-show="isShowMoreList">
                <li
                  @click="moreLiClick('start')"
                  v-show="chatInfo.state == 'close'"
                >
                  开始聊天
                </li>
                <li
                  @click="moreLiClick('supervising')"
                  v-show="chatInfo.state == 'supervising'"
                >
                  停止监督
                </li>
                <li
                  @click="moreLiClick('transfer')"
                  v-show="chatInfo.state == 'chatting'"
                >
                  转移聊天
                </li>
                <li
                  @click="moreLiClick('ban')"
                  v-show="
                    chatInfo.state == 'chatting' || chatInfo.state == 'close'
                  "
                >
                  禁止此访客
                </li>
                <li
                  @click="moreLiClick('finish')"
                  v-show="chatInfo.state == 'chatting'"
                >
                  结束聊天
                </li>
              </div>
            </div>
          </div>
          <div class="chat-content-main">
            <div class="no-chat" v-show="JSON.stringify(chatInfo) == '{}'">
              <svg aria-hidden="true" class="icon">
                <use href="#icon-chat"></use>
              </svg>
              <h1>No ongoing chats</h1>
              <p>
                Why not see who's on your website right now and start a chat
                proactively?
              </p>
            </div>
            <div
              class="chat-content-list"
              ref="chatContentList"
              @scroll="chatScroll"
              v-show="JSON.stringify(chatInfo) !== '{}'"
            >
              <div class="loadding" v-show="showChatLoad">
                <span class="load-img"></span
                ><span>Loading previous chats…</span>
              </div>

              <div>
                <template v-for="(item, index) in chatContent">
                  <!-- 系统消息显示 -->
                  <div
                    v-if="item.type == 'system_message'"
                    class="system-message"
                    :key="index"
                  >
                    <div class="time-line">
                      <div class="line"></div>
                      <div class="time">
                        {{ calculatedate(true, item.created_at) }}
                      </div>
                      <div class="line"></div>
                    </div>
                    <div class="message-text">
                      {{ item.text }}
                    </div>
                  </div>
                  <!-- 坐席消息显示 -->
                  <div
                    v-else-if="getAuthorInfo(item.author_id).type == 'agent'"
                    class="right-message"
                    :key="index"
                  >
                    <!-- {{ item }} -->
                    <div class="info">
                      <span class="head-img">
                        <img
                          width="100%"
                          height="100%"
                          :src="agentInfo.avatar"
                          alt=""
                        />
                      </span>
                      <span class="agent-name">
                        {{ calculatedate(false, item.created_at) }}
                        {{ getAuthorInfo(item.author_id).name }}
                      </span>
                    </div>
                    <div class="message">
                      <message :messageProp="item" :isAgentMes="true"></message>
                    </div>
                  </div>
                  <!-- 客户消息显示 -->
                  <div v-else class="left-message" :key="index">
                    <div class="info">
                      <span class="head-img">
                        {{
                          getAuthorInfo(item.author_id).name
                            ? getAuthorInfo(item.author_id).name.substring(0, 1)
                            : "uc"
                        }}
                      </span>
                      <span class="agent-name">
                        {{
                          getAuthorInfo(item.author_id).name
                            ? getAuthorInfo(item.author_id).name
                            : "Unnamed customer                                                          "
                        }}
                        {{ calculatedate(false, item.created_at) }}
                      </span>
                    </div>
                    <!-- 信息展示形式 -->
                    <!-- 普通 -->
                    <div class="message">
                      <message
                        :messageProp="item"
                        :isAgentMes="false"
                      ></message>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- 聊天输入框 -->
            <div
              style="position: relative; height: 160px"
              v-show="JSON.stringify(chatInfo) !== '{}'"
            >
              <!-- {{ chatInfo.state }} -->
              <div
                class="more-messgae"
                v-show="showMoreMessage"
                @click="moreMessageClick"
              >
                有{{ moreMessage }}条新消息
              </div>
              <chat-input
                @send="send"
                :isUseLoc="false"
                :isUseLink="false"
                :isUseVideo="false"
                :isUseUpload="false"
                :isUseTime="false"
                :autosize="{ minRows: 4, maxRows: 4 }"
                v-model="sendMessage"
                placeholder="请输入内容 ..."
                v-if="
                  chatType !== 'queued' &&
                  chatInfo.state !== 'banned' &&
                  chatInfo.state !== 'close'
                "
              ></chat-input>

              <div class="operation" v-else>
                <!-- 队列 -->
                <div class="queued" v-if="chatType == 'queued'">
                  <p class="info">queued</p>
                  <div class="oper-btn">
                    <el-button @click="operationClick(chatType)"
                      >queued</el-button
                    >
                  </div>
                </div>
                <!-- 禁止 -->
                <div class="banned" v-if="chatInfo.state == 'banned'">
                  <p>This customer has been banned.</p>
                </div>
                <!--  -->
                <div class="banned" v-if="chatInfo.state == 'close'">
                  <p>This customer has been closed.</p>
                </div>
              </div>
            </div>
          </div>

          <!-- 创建代理 -->
          <!-- <el-button type="primary" @click="createAgent">创建代理</el-button> -->
        </div>

        <!-- 访客信息 -->
        <div class="info-close" v-show="!infoExpand">
          <div class="chat-top">
            <i class="el-icon-more" @click="infoExpand = true"></i>
          </div>
        </div>
        <div class="cus-info" v-show="infoExpand">
          <div class="chat-top">
            <p>访客信息</p>
            <p @click="infoExpand = false">
              <i class="el-icon-close"></i>
            </p>
          </div>
          <cus-info-com
            :cusInfoProp="cusInfo"
            v-if="JSON.stringify(cusInfo) !== '{}'"
          >
          </cus-info-com>
          <el-skeleton v-else style="padding: 5px 20px">
            <template slot="template">
              <div
                style="
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                "
                v-for="(item, index) in 5"
                :key="index"
              >
                <el-skeleton-item
                  variant="circle "
                  style="width: 50px; height: 50px"
                />
                <div style="padding: 14px; width: 80%;}">
                  <el-skeleton-item variant="p" style="width: 100%" />
                  <el-skeleton-item variant="p" style="width: 100%" />
                </div>
              </div>
            </template>
          </el-skeleton>
        </div>
      </div>
      <!-- 所有聊天 -->
      <div class="cus-all" v-show="typeIndex == 1">
        <all-chat
          :cusListProp="cusList"
          :chatListProp="chatList"
          @openChat="openChat"
          ref="allChat"
        ></all-chat>
      </div>
      <!-- 转移聊天 -->
      <chat-dialog
        :dialogVisibleProp="dialogVisible"
        :dialogObj="dialogObj"
        @confirmDialog="confirmDialog"
        @closeDialog="dialogVisible = false"
        :chatID="chatID"
        :cusInfo="cusInfo"
      ></chat-dialog>
    </div>
  </div>
</template>
<script>
import * as chatApi from "@/views/liveChat/chatApi.js";
import { ChatSDK } from "@/views/liveChat/chatApi.js";
import chatInput from "@/components/ChatInput/index";
import chatDialog from "./components/dialog";
import AllChat from "./components/AllChat";
import chatInfoThumb from "./components/ChatInfoThumb";
import { calculateDate } from "@/views/liveChat/utils/calculateDate";
import Message from "./components/Message";
import sendCode from "@/views/liveChat/sendCode/index.vue";
import cusInfoCom from "./components/cusInfo";

export default {
  name: "liveChat",
  components: {
    chatInput,
    chatDialog,
    Message,
    AllChat,
    sendCode,
    chatInfoThumb,
    cusInfoCom,
    //  MapGroup: () => import("@/components/Map/index.vue"),
  },
  data() {
    return {
      isInstall: false, //是否安装代码
      licenseID: "", //码
      isShow: false,
      chatLocalToken: localStorage.getItem("liveChatToken") || "",
      cusList: [], //在线客户列表
      chatList: [], //所有聊天列表
      chatContent: [], //聊天内容
      chatInfo: {}, //会话信息
      sendMessage: "", //发送消息
      chatID: "", //当前会话id
      users: [], //当前会话成员
      cusInfo: {}, //当前客户信息
      agentInfo: {}, // 当前坐席信息
      UA: null, //浏览器信息解析对象
      localTimer: null, //当地时间定时器

      mapkey: "7bb0b2f57e5f8a4768bb02d1914847b7", //地理定位地图key  google
      isMap: true,
      typeIndex: 0, //切换我的聊天（0）和全部聊天（1）
      UC: "UC", //没有名字客户的统称
      acceptChat: true, //接受聊天开关
      isShowMoreList: false, //展示更多列表
      dialogVisible: false, //弹窗是否展示
      recipients: "all", //发送消息权限
      dialogObj: {
        type: "finish",
        title: "",
        list: [],
        btnText: "结束",
      }, //传给弹窗的数据
      activeNames: "1",
      chatType: "", //会话类型
      previous_thread_id: "", //前一个线程id
      debounceTimer: "", //防抖
      showChatLoad: false, // 列表内容加载
      showCreateEdit: true, //展示表单弹窗（添加潜客，创建工单）
      banList: [], //禁止列表
      showMoreMessage: false, //是否展示新消息提示
      moreMessage: 0, //聊天内容新消息条数
      instance: "",
      loginLoading: "", // 登录loading
      infoExpand: true, //客户信息列表是否展开
      chatTypeExpand: true, //会话类型列表是否展开
    };
  },
  computed: {
    // 监听在线客户列表  将state为chatting的客户回话列出来
    chattingList: {
      set(value) {
        this.chattingList = value;
      },
      get() {
        // 过滤出残缺的列表
        let usefulList = this.chatList.filter((item) => {
          return item.users.length > 1;
        });
        return usefulList.filter((item) => {
          for (let sItem of item.users) {
            //  item.state == "close" 会话关闭还存在列表中情况
            if (
              (sItem.id == this.agentInfo.id &&
                sItem.present &&
                !item?.properties?.supervising?.agent_ids?.includes(
                  this.agentInfo.id
                )) ||
              item.state == "close"
            ) {
              return true;
            }
          }
          return false;
        });
      },
    },
    chattingLength: {
      set(value) {
        this.chattingLength = value;
      },
      get() {
        const list = this.chattingList.filter((item) => {
          return item.state === undefined;
        });
        return list.length;
      },
    },
    chatMap: {
      set(value) {
        this.chatMap = value;
      },
      get() {
        let map = new Map();
        this.chatList.forEach((item) => {
          map[item.id] = item;
        });
        return map;
      },
    },
    // 监督聊天列表
    chatSupervisingList: {
      set(value) {
        this.chatSupervisingList = value;
      },
      get() {
        return this.chatList.filter((item) => {
          let tip = false;
          item.properties?.supervising?.agent_ids
            ?.split(",")
            .forEach((sTtem) => {
              if (sTtem == this.agentInfo.id) {
                tip = true;
                return tip;
              }
            });
          return tip;
        });
      },
    },
    // 排队聊天列表
    chatQueuedList: {
      set(value) {
        this.chatQueuedList = value;
      },
      get() {
        return this.chatList.filter((item) => {
          return (
            (item?.thread?.queue?.position !== undefined &&
              !item.is_followed) ||
            (item?.last_thread_summary?.queue?.position !== undefined &&
              !item.is_followed)
          );
        });
      },
    },
  },
  mounted() {
    // 初始化 有localToken 初始化， 没有重新登录
    if (this.chatLocalToken) {
      this.loginLoading = this.$loading({
        lock: true,
        text: "登陆中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ChatSDK.init({
        access_token: this.chatLocalToken,
      });
    } else {
      this.$router.push("/liveChatLogin");
    }
    // agent_disconnected  代理过期  退出登录
    ChatSDK.on("agent_disconnected", () => {
      this.$message({
        showClose: true,
        message: "登录失败，请重新登录",
        type: "error",
      });
      this.loginLoading.close();
      localStorage.setItem("liveChatToken", "");
      this.$router.push("/liveChatLogin");
    });
    // 登录完成后执行
    ChatSDK.on("ready", () => {
      this.loginLoading.close();
      this.isShow = true;
      // 会话转移
      ChatSDK.getAgentDetails()
        .then(({ chats_summary, license, my_profile }) => {
          this.loginLoading.close();
          // 保存坐席信息
          this.agentInfo = my_profile;
          localStorage.setItem("agentInfo", JSON.stringify(this.agentInfo));
          // 保存在线聊天信息
          this.chatList = chats_summary;
          // 选择第一个
          if (this.chatList.length > 0) {
            this.initCheckedChat();
          }

          // 是否安装代码
          this.licenseID = license.id;
          if (license?.website_last_activity) {
            this.isInstall = true;
          }
          // 页面初始化显示我的聊天中的第一个会话
          // this.chatID = this.chattingList[0]?.id
          // if (this.chattingList.length > 0) {
          //   const cus = this.chattingList[0].users.find((item) => {
          //     return item.type === "customer";
          //   });
          //   this.openChat(this.chattingList[0]?.id, cus.id);
          // }
        })
        .catch(() => {
          // localStorage.setItem("liveChatToken", "");
          // this.$router.push("/liveChatLogin");
        });
      // 会话转移
      // 坐席登录监听客户访问 incoming_customers 传入当前在线客户列表
      ChatSDK.on("incoming_customers", ({ payload: { customers } }) => {
        this.loginLoading.close();
        // 当存在该客户时将数据覆盖 ，不存在则存放在cusOnlineList中
        if (this.cusList.length == 0) {
          this.cusList.push(...customers);
          return;
        }
        for (let i in customers) {
          let equal = false;
          for (let j in this.cusList) {
            if (this.cusList[j].id == customers[i].id) {
              this.cusList[j] = customers[i];
              equal = true;
              break;
            }
          }
          if (equal) {
            this.cusList.push(customers[i]);
          }
        }
      });
      // incoming_customer 客户上线
      ChatSDK.on("incoming_customer", ({ payload }) => {
        let equal = false;
        for (let j in this.cusList) {
          if (this.cusList[j].id == payload.id) {
            this.$set(this.cusList, j, payload);
            equal = true;
            break;
          }
        }
        if (!equal) {
          this.cusList.push(payload);
        }
      });
      // 监听客户离开 left_customer
      ChatSDK.on("customer_left", ({ payload }) => {
        for (let j in this.cusList) {
          if (("客户离开", this.cusList[j].id == payload.id)) {
            this.$set(this.cusList[j], "state", "left");
            break;
          }
        }
      });
      ChatSDK.on("chat_transferred", async ({ payload }) => {
        const data = await chatApi.getChat(payload.chat_id);
        let tip = false;
        this.chatList.forEach((item, index) => {
          if (item.id == payload.chat_id) {
            this.$set(this.chatList, index, data);
            tip = true;
          }
        });
        if (!tip) {
          this.chatList.push(data);
        }
      });
      // 禁止客户聊天  ban_customer
      ChatSDK.on("customer_banned", ({ payload }) => {
        this.cusList.forEach((item, index) => {
          if (item.id == payload.customer_id) {
            this.cusList.splice(index, 1);
          }
        });
      });
      // 监听会话传入功能
      ChatSDK.on(
        "incoming_chat",
        async ({ payload: { chat } }) => {
          let tip = false;
          this.chatList.forEach((item, index) => {
            if (item.id == chat.id) {
              this.$set(this.chatList, index, chat);
              tip = true;
            }
          });
          if (!tip) {
            this.chatList.push(chat);
          }
          // 改变当前会话状态
          // this.chatInfo = chat
        }
      );
      // 监听会话关闭
      ChatSDK.on(
        "chat_deactivated",
        async ({ payload: { chat_id, user_id } }) => {
          // 修改chatList 判断是客户关闭还是坐席关闭 （user_id）
          // 修改用户状态
          this.cusList.forEach((item) => {
            item.chats.forEach((sItem) => {
              // 修改被监听用户状态
              if (sItem.chat_id == chat_id) {
                this.$set(item, "state", "browsing");
                // 删除监督
                return;
              }
            });
          });
          // // 如果是在禁止列表不改变state
          // if(this.banList.includes(chat_id)){
          //   return ;
          // }
          //
          if (user_id !== this.agentInfo.id || user_id == undefined) {
            const data = await chatApi.getChat(chat_id);

            if (this.chatInfo.id == chat_id) {
              this.chatInfo.state = "close";
            }
            // 改变chatList中的state
            this.chatList.forEach((item, index) => {
              if (item.id == data.id) {
                this.$set(data, "state", "close");
                this.$set(this.chatList, index, data);
              }
            });
          }
          // this.$bus.$emit('chat_properties_updated')
        }
      );
      // 监听会话属性改变
      ChatSDK.on(
        "chat_properties_updated",
        ({ payload: { chat_id, properties } }) => {
          // 通过递归克隆
          this.chatList.forEach((item) => {
            if (chat_id == item.id) {
              this.updateProper(properties, item.properties);
              // if(Object.keys(properties).length==0){
              //   this.$set(item,'properties',properties)
              // } else{
              //   Object.keys(properties).forEach(sItem=>{
              //   this.$set(item.properties,sItem,properties[sItem])
              // })
              // }
              this.$bus.$emit("chat_properties_updated");
            }
          });
        }
      );
      // 监听发送事件
      ChatSDK.on("send_event", (data) => {
        if (data.success) {
          // alert(data.payload.error.message);
        }
      });
      // 监听接受消息事件
      ChatSDK.on("incoming_event", (data) => {
        const cList = this.$refs.chatContentList;
        /**
         * 判断是否为当前会话窗口
         * 1 将会话数据添加到会话窗口
         *    是谁发的
         *    1.1坐席，滚动条到最底部
         *    1.2客户  判断滚动条是否在底部
         *      1.2.1  在底部  滚动条到最底部
         *      1.2.2  不在底部 新消息提示
         * 2 将在会话列表做提示
         */
        let lastMessage;
        // 根据消息类型展示
        if (data.payload.event.type == "file") {
          lastMessage = "file message";
        } else {
          lastMessage = data.payload.event.text;
        }
        if (this.chatID == data.payload.chat_id) {
          this.chatContent.push(data.payload.event);
          if (data.payload.event.author_id == this.agentInfo.id) {
            this.$nextTick(() => {
              this.chatConScrollBtm();
            });
          } else {
            if (cList.scrollTop == cList.scrollHeight - cList.offsetHeight) {
              this.$nextTick(() => {
                this.chatConScrollBtm();
              });
            } else {
              this.showMoreMessage = true;
              this.moreMessage += 1;
            }
          }
          this.$bus.$emit("lastMessage", data.payload.chat_id, 0, lastMessage);
        } else {
          this.chatList.forEach((item, index) => {
            if (item.id == data.payload.chat_id) {
              this.chatList[index].lastMessage = lastMessage;
              if (this.chatList[index]?.noReadNum) {
                this.chatList[index].noReadNum += 1;
              } else {
                this.chatList[index].noReadNum = 1;
              }
              this.$bus.$emit(
                "lastMessage",
                data.payload.chat_id,
                this.chatList[index].noReadNum,
                this.chatList[index].lastMessage
              );
            }
          });
        }
      });
    });
  },
  methods: {
    // 退出登录liveChat
    logOut() {
      localStorage.setItem("liveChatToken", "");
      this.chatLocalToken = "";
      chatApi.ChatSDK.destroy();
      this.$router.push("/liveChatLogin");
      // this.login()
    },
    // 创建坐席
    // createAgent(){
    //   const data = chatApi.createAgent()
    // },
    calculatedate(showDate, date) {
      return calculateDate(showDate, date);
    },
    },
    // 打开聊天窗口,获取客户信息
    /**
     * @param  {String}  all 是否是在全部列表点击open chat
     */
    async openChat(chatID, cusID, chatType, all = false) {
      // 如果当前会话和将打开的会话一样将不执行操作
      if (this.chatID == chatID && !all) {
        return;
      }
      this.chatType = chatType;
      if (chatType == "supervising") {
        this.activeNames = "2";
        this.recipients = "agents";
      } else {
        this.recipients = "all";
      }
      // 列表被选中项
      this.typeIndex = 0;

      this.chatID = chatID;
      const data = await chatApi.getChat(chatID);
      this.chatInfo = data;

      // 给chatInfo添加state
      this.chatInfo.state = chatType;
      if (this.banList.includes(chatID)) {
        this.chatInfo.state = "banned";
      }
      //
      this.previous_thread_id = data?.thread?.previous_thread_id;
      // 聊天内容
      this.chatContent = data.thread.events;

      // 聊天成员
      this.users = data.users;
      // 获取聊天cus客户的基本信息
      const cusInfo = await chatApi.getCustomer(cusID);
      this.cusInfo = cusInfo;

      // 初始化地图
      // this.$nextTick(() => {
      //   this.$refs.mapGroup.init();
      // });
      this.chatConScrollBtm();
      let target = this.$refs.chatContentList;
      this.$nextTick(() => {
        if (target.scrollHeight == target.clientHeight) {
          this.superWhell();
        }
      });
    },
    // 输入消息
    input() {},
    // 发送消息
    send() {
      if (this.sendMessage == "") {
        return;
      }
      ChatSDK.sendMessage(this.chatID, this.sendMessage, this.recipients)
        .then(() => {
          this.sendMessage = "";
          this.chatConScrollBtm();
          // get event_id or handle a success scenario
        })
        .catch(() => {
          // catch an error object
        });
    },
    // 获取聊天成员基本信息用来展示会话列表数据
    getAuthorInfo(author_id) {
      return this.users.filter((item) => {
        return item.id === author_id;
      })[0];
    },
    // 过去转让或邀请在线坐席列表
    async getAgentList() {
      await chatApi.getAgentList(this.chatID);
      // 邀请坐席
      await chatApi.addUserToChat(
        this.chatID,
        "1604884844@qq.com",
        "agent",
        "all"
      );
    },
    // 生成代理
    async createAgent() {
      const params = {
        id: "anent1113@qq.com",
        name: "管理员31111号",
      };
      await chatApi.agentApi("create_agent", params);
    },

    // 获取正在聊天列表
    getchattingList() {},
    /**
     * @param {String} type 按钮类型
     */
    async moreLiClick(type) {
      this.isShowMoreList = false;
      this.dialogVisible = true;
      let param = {};
      let data;
      const list = [];
      switch (type) {
        case "start":
          this.dialogVisible = false;
          param = {
            id: this.chatID,
          };
          data = await chatApi.resumeChat(param);
          if (data?.error) {
            return;
          } else {
            this.$set(this.chatInfo, "state", "chatting");
          }
          break;
        case "supervising":
          this.dialogObj = {
            type: "supervising",
            title: "停止监督",
            list: list,
            btnText: "停止",
          };
          // 监督聊天
          break;
        case "transfer":
          this.dialogObj = {
            type: "transfer",
            title: "转移聊天",
            list: list,
            btnText: "转移",
          };
          break;
        case "ban":
          this.dialogObj = {
            type: "ban",
            title: "禁止此访客",
            list: list,
            btnText: "禁止",
          };
          break;
        case "finish":
          this.dialogObj = {
            type: "finish",
            title: "",
            list: list,
            btnText: "停用聊天",
          };
          break;
      }
    },
    // 弹框确认
    async confirmDialog(type, banTime, transfer) {
      let param = {};
      let data;
      switch (type) {
        case "supervising":
          param = {
            state: "supervising",
            chats: [
              {
                chat_id: this.chatID,
              },
            ],
          };
          this.$refs.allChat.smallBtnClick(param);
          this.dialogVisible = false;
          break;
        case "transfer":
          param = {
            id: this.chatID,
            target: {
              type: "agent",
              ids: [transfer],
            },
          };
          data = await chatApi.transfer_chat(param);
          if (data?.error) {
            // alert("error", data.error);
          } else {
            this.dialogVisible = false;
          }
          break;
        case "ban":
          param = {
            id: this.cusInfo.id,
            ban: {
              days: banTime,
            },
          };
          data = await chatApi.ban_customer(param);
          if (data?.error) {
            // alert("錯誤", data.error);
          } else {
            this.dialogVisible = false;
            this.banList.push(this.chatInfo.id);
            // 设置会话列表中某一项的state为ban
            this.$set(this.chatInfo, "state", "banned");
            for (let index in this.chatList) {
              if (this.chatList[index].id == this.chatInfo.id) {
                this.$set(this.chatList[index], "state", "banned");
              }
            }
          }
          break;
        case "finish":
          data = await chatApi.deactivate_chat({
            id: this.chatID,
          });
          if (data?.error) {
            // alert("error", data.error);
          } else {
            this.dialogVisible = false;
            this.deleteChat(this.chatID, "chatting", this.chatIndex);
          }
          this.dialogVisible = false;
          break;
      }
    },
    // 将聊天内容滚动条调到在底部
    chatConScrollBtm() {
      const cList = this.$refs.chatContentList;
      cList.scrollTop = cList.scrollHeight;
    },
    handleChangeCollapse(val) {
      this.activeNames = val;
    },
    // 修改属性
    updateProper(fromV, toV) {
      for (let name in fromV) {
        if (Object.prototype.toString.call(fromV[name]) == "[object Object]") {
          toV[name] = {};
          this.updateProper(fromV[name], toV[name]);
        } else {
          toV[name] = fromV[name];
        }
      }
    },

    // 删除chat
    deleteChat(chatID, cusUserID, chatType, chatIndex) {
      this.cusInfo = {};
      this.chatInfo = {};
      this.changeChat(chatID, chatType, chatIndex);
      this.chatList.forEach((item, index) => {
        if (item.id == chatID) {
          this.$delete(this.chatList, index);
        }
      });
    },
    initCheckedChat() {
      let cusUserID;
      let chatType;
      if (this.chattingList.length > 0) {
        chatType = "chattingList";
        this.chatInfo = this.chattingList[0];
        this.activeNames = "1";
      } else if (this.chatSupervisingList.length > 0) {
        chatType = "chatSupervisingList";
        this.chatInfo = this.chatSupervisingList[0];
        this.activeNames = "2";
      } else if (this.chatQueuedList.length > 0) {
        chatType = "chattichatQueuedListngList";
        this.chatInfo = this.chatQueuedList[0];
        this.activeNames = "3";
      }
      for (let i = 0; i < this.chatInfo.users.length; i++) {
        if (this.chatInfo.users[i].type == "customer") {
          cusUserID = this.chatInfo.users[i].id;
          this.chatInfo = this.chattingList[0];
        }
      }
      this.openChat(this.chatInfo.id, cusUserID, chatType);
    },
    changeChat(chatID, chatType, chatIndex) {
      let cusUserID;
      if (chatType == "chatting") {
        if (chatIndex - 1 >= 0) {
          this.chatInfo = this.chattingList[chatIndex - 1];
          this.activeNames = "1";
        } else if (chatIndex + 1 < this.chattingList.length) {
          this.chatInfo = this.chattingList[chatIndex + 1];
          this.activeNames = "1";
        } else {
          if (this.chatSupervisingList.length > 0) {
            this.chatInfo = this.chatSupervisingList[0];
            this.activeNames = "2";
            chatType = "supervising";
          } else if (this.chatQueuedList.length > 0) {
            this.chatInfo = this.chatQueuedList[0];
            this.activeNames = "3";
            chatType = "queued";
          }
        }
      } else if (chatType == "supervising") {
        if (chatIndex - 1 >= 0) {
          this.chatInfo = this.chatSupervisingList[chatIndex - 1];
          this.activeNames = "2";
        } else if (chatIndex + 1 < this.chatSupervisingList.length) {
          this.chatInfo = this.chatSupervisingList[chatIndex + 1];
          this.activeNames = "2";
        } else {
          if (this.chattingList.length > 0) {
            this.chatInfo = this.chattingList[this.chattingList.length - 1];
            this.activeNames = "1";
            chatType = "chatting";
          } else if (this.chatQueuedList.length > 0) {
            this.chatInfo = this.chatQueuedList[0];
            this.activeNames = "3";
            chatType = "queued";
          }
        }
      } else if (chatType == "queued") {
        if (chatIndex - 1 >= 0) {
          this.chatInfo = this.chatQueuedList[chatIndex - 1];
          this.activeNames = "3";
        } else if (chatIndex + 1 < this.chatQueuedList.length) {
          this.chatInfo = this.chatQueuedList[chatIndex + 1];
          this.activeNames = "3";
        } else {
          if (this.supervising.length > 0) {
            this.chatInfo = this.supervising[this.supervising.length - 1];
            this.activeNames = "2";
            chatType = "supervising";
          } else if (this.chattingList.length > 0) {
            this.chatInfo = this.chattingList[0];
            this.activeNames = "1";
            chatType = "chatting";
          }
        }
      }

      for (let i = 0; i < this.chatInfo.users.length; i++) {
        if (this.chatInfo.users[i].type == "customer") {
          cusUserID = this.chatInfo.users[i].id;
        }
      }
      this.openChat(this.chatInfo.id, cusUserID, chatType);
    },
    // 聊天内容底部操作按钮
    async operationClick(chatType) {
      // 处理队列
      if (chatType == "queued") {
        const param = {
          id: this.chatID,
          target: {
            type: "agent",
            ids: [this.agentInfo.id],
          },
        };
        await chatApi.transfer_chat(param);
        this.chatType = "";
      }
    },
    // 获取状态
    getStateFromChattingList() {
      return this.chattingList.filter((item) => {
        return item?.state !== undefined;
      })[0];
    },
    // 监听滚动条
    chatScroll(e) {
      let event = e || window.event;
      this.debounceFun(this.chatLoadding(event), 100);
    },
    async chatLoadding(event) {
      // 去掉新消息提示
      if (
        event.target.scrollTop ==
        event.target.scrollHeight - event.target.offsetHeight
      ) {
        this.showMoreMessage = false;
        this.moreMessage = 0;
      }
      if (!this.previous_thread_id) {
        return;
      }
      if (event.target.scrollTop == 0) {
        this.showChatLoad = true;
        let height = event.target.scrollHeight;
        const data = await chatApi.getChat(
          this.chatID,
          this.previous_thread_id
        );
        this.previous_thread_id = data?.thread?.previous_thread_id;
        this.chatContent.unshift(...data.thread.events);
        this.showChatLoad = false;
        this.$nextTick(() => {
          event.target.scrollTop = event.target.scrollHeight - height;
        });
      }
    },
    // 监听鼠标滚轮
    superWhell() {
      //  this.$refs.chatContentList.removeEventListener("onmousewheel")
      this.$refs.chatContentList.addEventListener("mousewheel", this.whellload);
      this.$refs.chatContentList.addEventListener(
        "DOMMouseScroll",
        this.whellload
      );
    },
    // 监听聊天内容鼠标滚轮
    whellload() {
      let target = this.$refs.chatContentList;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () => {
        if (!this.previous_thread_id) {
          return;
        }

        this.showChatLoad = true;
        let height = target.scrollHeight;
        const data = await chatApi.getChat(
          this.chatID,
          this.previous_thread_id
        );
        this.previous_thread_id = data?.thread?.previous_thread_id;
        this.chatContent.unshift(...data.thread.events);
        this.showChatLoad = false;

        this.$nextTick(() => {
          target.scrollTop = target.scrollHeight - height;
          if (target.scrollHeight !== target.clientHeight) {
            this.$refs.chatContentList.removeEventListener(
              "mousewheel",
              this.whellload
            );
            this.$refs.chatContentList.removeEventListener(
              "DOMMouseScroll",
              this.whellload
            );
          }
        });
      }, 100);
    },
    //防抖
    debounceFun(fun, time) {
      let timer;
      return function () {
        clearTimeout(timer);
        setTimeout(() => {
          fun();
        }, time);
      };
    },

    // 新消息提示点击事件
    moreMessageClick() {
      this.showMoreMessage = false;
      this.moreMessage = 0;
      this.chatConScrollBtm();
    }
};
</script>
<style lang="scss" scoped>
@mixin blue-border-btn {
  color: #2d6cfc;
  background: #fff;
  border: 1px solid #2d6cfc;
}
::v-deep .el-collapse-item__wrap {
  border: none;
  background-color: rgb(243, 247, 249);
}
::v-deep .el-collapse-item__header {
  border: none;
  padding: 0px 20px;
  color: rgb(66, 77, 87);
  font-size: 18px;
  font-weight: 600;
  background-color: rgb(243, 247, 249);
}
::v-deep .el-collapse {
  background-color: rgb(243, 247, 249);
  border: none;
}
.chat-main {
  margin: 15px 11px 0px;
  display: flex;
  // justify-content: center;
  box-sizing: border-box;
  height: 100%;
  background: #fff;
  .chat-checked {
    background: #ecf4ff !important;
    border-left: 5px solid rgb(67, 132, 245) !important;
    &:hover {
      border-left: 5px solid rgb(67, 132, 245) !important;
    }
  }
  .my-chat {
    display: flex;
    justify-content: center;
    flex: 1;
    .chat-list {
      width: 280px;
      height: 100%;
      border-right: 1px solid #dedede;
      background-color: rgb(243, 247, 249);
      border-color: rgb(203, 212, 222);
      // .chat-list-content {
      //   height: 40px;
      //   margin-bottom: 12px;
      //   .chat-list-content-item {
      //     &:hover {
      //       background: #ecf4ff;
      //       border-color: #ecf4ff;
      //     }
      //     padding: 5px 20px;
      //     border-left: 5px solid #fff;
      //     cursor: pointer;
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     .left {
      //       display: flex;
      //       justify-content: flex-start;
      //       align-items: center;
      //       margin-right: 5px;
      //       color: #666666;
      //       .cus-icon {
      //         width: 40px;
      //         height: 40px;
      //         background: rgb(57, 76, 130);
      //         color: #fff;
      //         border-radius: 20px;
      //         display: flex;
      //         justify-content: center;
      //         align-items: center;
      //         margin-right: 5px;
      //       }
      //     }
      //     .right {
      //       display: flex;
      //       justify-content: center;
      //       align-items: center;
      //       flex-direction: column;
      //       .message-num {
      //         width: 12px;
      //         height: 12px;
      //         background: #b9070c;
      //         border-radius: 15px;
      //         font-size: 12px;
      //         color: #fff;
      //         display: flex;
      //         justify-content: center;
      //         align-items: center;
      //       }
      //     }
      //   }
      // }
    }
    .chat-content {
      min-width: 500px;
      height: 100%;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      border-right: 1px solid #dedede;
      .chat-top {
        justify-content: space-between;
        border-bottom: 1px solid #dedede;
        .el-icon-close{
          color:black;
          font-size: 18px;
          font-weight: bold;
        }
        .more {
          position: relative;
          i {
            transform: rotate(90deg);
            cursor: pointer;
          }
          .more-list {
            position: absolute;
            min-width: 200px;

            border: 1px solid #ccc;
            top: 30px;
            right: 0px;
            background: #fff;
            box-shadow: 0 1px 10px 0 rgb(66 77 87 / 30%);
            z-index: 999;
            li {
              height: 30px;
              line-height: 30px;
              margin: 5px 0px;
              padding: 0px 20px;
              color: #666666;
              font-size: 14px;
              font-weight: normal;
              cursor: pointer;
              &:hover {
                background: hsla(222, 97%, 58%, 0.055);
                cursor: pointer;
              }
            }
          }
        }
      }
      .chat-content-main {
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .no-chat {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .icon {
            width: 260px;
            height: 160px;
          }
        }
        .main {
          padding: 0px 20px;
        }
      }
      .chat-content-list {
        // height: calc(100% - 50px);
        flex: 1;
        overflow: auto;
        padding: 0px 10px;
        position: relative;
        // .message{
        //     text-align: left;
        // }
        .loadding {
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px;
          border-bottom: 1px solid #ccc;
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          .load-img {
            height: 16px;
            width: 16px;
            border-radius: 8px;
            border: 3px solid #ccc;
            border-radius: 16px;
            border: 3px solid #ccc;
            border-color: blue #ccc #ccc #ccc;
            animation: identifier 1s infinite linear;
          }
          @keyframes identifier {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        & > div {
          margin: 10px 0px;
        }
        .head-img {
          width: 30px;
          height: 30px;
          margin: 0px 5px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .left-message {
          // width: 500px;
          // text-align: left;
          // margin: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .head-img {
            background: rgb(57, 76, 130);
            color: #fff;
          }
          .info {
            display: flex;
            justify-content: flex-end;
            align-content: center;
          }
          .message {
            margin-top: -5px;
            margin-left: 40px;
            max-width: 60%;
          }
        }
        .right-message {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .info {
            display: flex;
            justify-content: flex-end;
            flex-direction: row-reverse;
            align-content: center;
          }

          .message {
            max-width: 60%;
            margin-right: 40px;
            margin-top: -5px;
          }
        }
        .system-message {
          // text-align: center;
          color: #ccc;
          padding: 10px 0px;
          .time-line {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0px;
            .line {
              height: 1px;
              background: #ccc;
              flex: 1;
            }
            .time {
              padding: 0px 20px;
            }
          }
          .message-text {
            text-align: center;
          }
        }
      }
      .more-messgae {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;

        position: absolute;
        left: 0px;
        top: -20px;
        width: 100%;
        box-shadow: 0px 0px 10px #ccc;
        cursor: pointer;
      }
      ::v-deep .emoji-list {
        top: -370px;
      }

      .send {
        height: 50px;
      }
      .operation {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #dedede;
        .queued {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 100%;
          width: 100%;
        }
        .banned {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .open-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          button {
            @include blue-border-btn;
          }
        }
      }
    }
    .info-close {
      width: 40px;
      background-color: rgb(243, 247, 249);
      .chat-top {
        border: none;
        justify-content: center;
        i {
          cursor: pointer;
          transform: rotate(90deg);
        }
      }
    }
    .cus-info {
      width: 300px;
      height: 100%;
      overflow: auto;
      background-color: rgb(243, 247, 249);
      .chat-top {
        border: none;
        justify-content: space-between;
      }
    }
  }
  .chat-top {
    height: 60px;
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #dedede;
    p {
      cursor: pointer;
      span {
        cursor: pointer;
      }
    }
  }
  .chat-type-expand {
    width: 40px;
  }
  .chat-type {
    width: 250px;
    height: 100%;
    border-right: 1px solid #dedede;
    .type-top {
      justify-content: space-between;
      .search {
        margin-right: 10px;
      }
    }

    .type-list {
      position: relative;
      height: calc(100% - 60px);
      p {
        height: 32px;
        font-size: 12px;
        color: #666;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        border-bottom: 1px solid #dedede;
        cursor: pointer;
        span {
          cursor: pointer;
        }
      }
      .accept-chat {
        position: absolute;
        width: calc(100% - 10px);
        box-sizing: border-box;
        left: 10px;
        bottom: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          margin-right: 5px;
        }
      }
    }
  }

  .cus-all {
    flex: 1;
    // background: red;
    height: 100%;
  }
}
</style>