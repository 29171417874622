<template>
  <div id="ggMap" class="ggMap"></div>
</template>
<script>
import MapLoader from "@/components/Map/initMapUtils";
export default {
  name: "ChatMap",
  props: {
    locationProp: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      ggMapObject: "", //地图对象
      location: this.locationProp,
      countryCode: 'CN',
    };
  },
  watch: {
    locationProp: {
      handler(newValue) {
        this.location = newValue;
        this.init();
      },
      deep: false,
      immediate: false,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.countryCode == "CN") {
        MapLoader("AIzaSyBiqnTIVrD2mgx4YiIpd3zq6ynQl7qYAE4", "Gmap").then(
          () => {
            
            // eslint-disable-next-line no-undef
            this.ggMapObject = new google.maps.Map(
              document.getElementById("ggMap"),
              {
                center: this.location,
                zoom: 13,
                // scrollwheel: false, // 滚轮
                // disableDefaultUI: true, // 禁用默认ui
              }
            );
            this.setMarker();
          },
          () => {}
        );
      } else {
        MapLoader("7bb0b2f57e5f8a4768bb02d1914847b7", "Amap").then(
          (map) => {
            this.ggMapObject = new map.Map("ggMap", {
              zoom: 11, //级别
              center: [this.location.lng, this.location.lat], //中心点坐标
              viewMode: "3D", //使用3D视图
            });
            this.setMarker(map)
          },
          () => {}
        );
      }
    },
    setMarker(map) {
      if (this.countryCode == "CN") {
        let marker = {
          position: this.location,
          map: this.ggMapObject,
        };
        // eslint-disable-next-line no-undef
        new google.maps.Marker(marker);
      } else {
        var marker = new map.Marker({
          position: new map.LngLat(this.location.lng, this.location.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: "北京",
        });
        this.ggMapObject.add(marker);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ggMap {
  width: 100%;
  height: 100%;
}
</style>