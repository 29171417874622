<template>
  <div class="form">
    <div class="title">{{ formType }}</div>
    <div class="content">
      <div class="item" v-for="item in fields" :key="item.id">
        <p class="label">{{ item.label }}</p>
        <p class="value">
          <span class="email" v-if="item.type == 'email'">
            <a :href="'mailto:' + item.answer"> {{ item.answer }}</a>
          </span>
          <span v-else>
            {{item.type!=="radio"? item.answer:item.answer.label }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FormMessage",
  props: {
    // 文件内容
    fields: {
      type: Array,
      value: () => {
        return [
          {
            answer: "副本客户一号",
            id: "2001",
            label: "Name:",
            type: "name",
          },
          {
            answer: "number1F@qq.com",
            id: "2002",
            label: "E-mail:",
            type: "email",
          },
        ];
      },
    },
    formType: {
      type: String,
      value: "",
    },
  },
  computed: {
    title() {
      return this.formType == "preForm" ? "123" : "123123";
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  min-width: 200px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  .title {
    color: rgb(66, 77, 87);
    background-color: rgb(243, 247, 249);
    padding: 12px 22px;
    text-align: center;
  }
  .content {
    padding: 12px 20px;
    .item {
      margin: 10px 0px;
      .label {
        color: rgba(66, 77, 87, 0.6);
        margin-bottom: 5px;
      }
      .value {
        .email {
          color: blue;
        }
      }
    }
  }
}
</style>