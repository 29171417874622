/*
 * @Author: your name
 * @Date: 2022-03-25 11:15:24
 * @LastEditTime: 2022-05-23 16:39:13
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\components\Map\initMapUtils.js
 */
/**
 * @description 加载地图基础组件js
 * @param {key, type} 秘钥 ak/key、地图类型type：百度地图-Bmap，高德地图-Amap，谷歌地图-Gmap
 */
export default function MapLoader(key, type) {
  if (type === 'Bmap') {
    // 百度地图
    return new Promise((resolve, reject) => {
      if (window.BMap) {
        resolve(window.BMap);
      } else {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://api.map.baidu.com/api?v=3.0&ak=' + key + '&callback=onBMapCallback';
        script.onerror = reject;
        document.head.appendChild(script);
      }
      window.onBMapCallback = function () {
        resolve(window.BMap);
      };
    });
  } else if (type === 'Amap') {
    // 高德地图
    return new Promise((resolve, reject) => {
      if (window.AMap) {
        resolve(window.AMap);
      } else {
        window._AMapSecurityConfig = {
          securityJsCode: window.Glod['AMAP_SECURITY_KEY'],
        }
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://webapi.amap.com/maps?key=' + key + '&v=1.4.15&plugin=AMap.Geocoder&callback=initAMap';
        script.onerror = reject;
        document.head.appendChild(script);
      }
      window.initAMap = () => {
        resolve(window.AMap);
      };
    });
  } else if (type === 'Gmap') {
    // 谷歌地图
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + key + '&callback=initMap&language=en';
        script.onerror = reject;
        document.head.appendChild(script);
      }
      window.initMap = () => {
        resolve(window.google);
      };
    });
  }

}
