<template>
  <div>
    <div class="info-content" >
      <!-- 基本信息 -->
      <div class="item">
        <!-- 顶部信息 -->
        <div class="item-top">
          <div class="title">基本信息</div>
          <div class="add-cus">
            <el-button
              type="primary"
              @click="clickEdit('WorkOrderstdobj02021', 'cloudcccase','008')"
              >创建工单</el-button
            >
            <el-button type="primary" @click="clickEdit('lead', 'Lead','004')"
              >添加潜客</el-button
            >
          </div>
        </div>
        <!-- 客户 头像 名称 email -->
        <div class="item-cus-info">
          <div class="headImg">
            {{ cusInfo.name?cusInfo.name.substring(0,1):'uc' }}
          </div>
          <div class="right">
            <p class="name">{{ cusInfo.name?cusInfo.name:'Unnamed customer' }}</p>
            <p class="email">{{ cusInfo.email }}</p>
          </div>
        </div>
        <!-- 用户当地时间 -->
        <div class="time" key="time">
          <svg aria-hidden="true" class="icon i search">
            <use href="#icon-shijian"></use>
          </svg>
          {{ localTime }}当地时间
        </div>
        <!-- 地点 -->
        <div class="location">
          <svg aria-hidden="true" class="icon i search">
            <use href="#icon-weizhi"></use>
          </svg>
          {{ cusInfo.last_visit.geolocation.region }}，{{
            cusInfo.last_visit.geolocation.city
          }}，{{ cusInfo.last_visit.geolocation.country }}
        </div>
        <!-- 地图显示地点 -->
        <div class="map" key="map">
          <chat-map
            :locationProp="{
              lat: Number(cusInfo.last_visit.geolocation.latitude),
              lng: Number(cusInfo.last_visit.geolocation.longitude),
            }"
          >
          </chat-map>
        </div>
      </div>
      <!-- 聊天前表格 -->
      <div class="item chat-table">
        <!-- 顶部信息 -->
        <div class="item-top">
          <div class="title">聊天前表格</div>
        </div>
        <p>
          <span>姓名：</span><span>{{ cusInfo.name }}</span>
        </p>
        <p>
          <span>邮箱：</span><span>{{ cusInfo.email }}</span>
        </p>
      </div>
      <!-- 登录页面 -->
      <div class="item">
        <div class="item-top">
          <div class="title">登录页面</div>
        </div>
        <div
          v-for="(item, index) in cusInfo.last_visit.last_pages"
          :key="index"
        >
          <p>
            <span>{{ item.title }}</span>
          </p>
          <p>
            <a :href="item.url">{{ item.url }}</a>
          </p>
        </div>
      </div>
      <!-- 附加信息 （次数，最后一次访问等信息） -->
      <div class="item">
        <div class="item-top">
          <div class="title">附加信息</div>
        </div>
        <p>
          <span>回访者：</span
          ><span
            >{{ cusInfo.statistics.visits_count }}次访问，{{
              cusInfo.statistics.threads_count
            }}次会话</span
          >
        </p>
        <p>
          <span>最后看到：</span
          ><span>{{ campareDate(cusInfo.last_visit.ended_at) }}</span>
        </p>
      </div>
      <!-- 用什么方式访问的网站 -->
      <div class="item">
        <div class="item-top">
          <div class="title">设备信息</div>
        </div>
        <p>
          <span>IP地址：</span><span>{{ cusInfo.last_visit.ip }}</span>
        </p>
        <p>
          <span>操作系统/设备：</span
          ><span>{{ UA.os.name }}({{ UA.os.version }})</span>
        </p>
        <p>
          <span>浏览器：</span
          ><span>{{ UA.browser.name }}({{ UA.browser.version }})</span>
        </p>
      </div>
    </div>
    <!-- 添加潜客 -->
      <create-edit-obj
        ref="createEditObj"
        :prefix="prefix"
        :liveChat="true"
        :liveChatEmail="cusInfo.email"
        :liveChatName="cusInfo.name"
      >
      </create-edit-obj>
  </div>
</template>

<script>
import ChatMap from "./ChatMap";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
export default {
  name: "cusInfoCom",
  components: {
      ChatMap,
      createEditObj
  },
  props: {
    cusInfoProp: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch:{
      cusInfoProp:{
          handler(newV){
              this.cusInfo = newV
          },
          deep:true,
          immediate:true
      }
  },
  data() {
    return {
      cusInfo: this.cusInfoProp,
      localTime: "", // 当地时间
      UA: {},
      prefix:""
    };
  },
  mounted() {
    // cus 客户浏览器信息
    this.UA = this.getUA(this.cusInfo.last_visit.user_agent);
    // 用户当地时间
    this.getLocalTime(this.cusInfo.last_visit.geolocation.timezone);
  },
  methods: {
    //  获取浏览器对象信息
    getUA(ua) {
      // eslint-disable-next-line no-undef
      let parser = new UAParser();
      parser.setUA(ua);
      return parser.getResult();
    },
    // 获取用户当地时间
    getLocalTime(timezone) {
      this.localTime = new Date()
        .toLocaleString("en-US", { timeZone: timezone })
        .split(",")[1]
        .substring(-1, 5);
      clearInterval(this.localTimer);
      this.localTimer = setInterval(() => {
        this.localTime = new Date()
          .toLocaleString("en-US", { timeZone: timezone })
          .split(",")[1]
          .substring(-1, 5);
      }, 60000);
    },
      // 最后查看的日期
    campareDate(lastVisitDate) {
      const nowDate = new Date();
      const lastDate = new Date(lastVisitDate);
      if (
        nowDate.toString().split(" ")[2] === lastDate.toString().split(" ")[2]
      ) {
        return "今天";
      } else {
        return (
          lastDate.toString().split(" ")[1] +
          " " +
          lastDate.toString().split(" ")[2]
        );
      }
    },
    // 添加潜客，创建工单
    clickEdit(objId, objApi, prefix) {
      this.prefix = prefix
      this.$refs.createEditObj.objId = objId;
      this.$refs.createEditObj.prefix = prefix;
      this.$refs.createEditObj.objectApi = objApi;
      this.$refs.createEditObj.add();
    },
  },
};
</script>

<style lang="scss" scoped>
.info-content {
  padding: 0px 20px 0px;
}
.item {
  border: 1px solid #dedede;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 12px;
  font-size: 12px;
  background: #fff;
  a {
    color: #2d6cfc;
  }
  a:visited {
    color: #2d6cfc;
  }
  a:active {
    color: #2d6cfc;
  }
  .time {
    color: #666;
  }
  .location {
    color: #666;
  }
  .map {
    width: 100%;
    height: 200px;
  }
  & > p {
    margin: 4px 0px;
    & > span {
      color: #666;
      &:first-of-type {
        color: #333;
      }
    }
  }
  .item-top {
    margin-bottom: 8px;
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    flex-wrap: wrap;
    .add-cus {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    button {
      background: #2d6cfc;
      color: #fff;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .item-cus-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .headImg {
      height: 26px;
      width: 26px;
      border-radius: 13px;
      background: rgb(57, 76, 130);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
    .right {
      .name {
        color: #333;
      }
      .email {
        color: #666;
      }
    }
  }
  & > div {
    margin-bottom: 12px;
  }
}
</style>