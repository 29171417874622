<template>
  <div class="chat-list-content">
    <template v-for="(user, userIndex) in chat.users">
      <div
        v-if="user.type == 'customer'"
        :key="userIndex"
        :class="{
          'chat-checked': chatID == chat.id,
          'chat-list-content-item': true,
          'close-item': chat.state == 'close' || chat.state == 'banned',
        }"
        @click="openChat(chat.id, user.id)"
      >
        <!-- {{chat }} -->
        <div class="left">
          <div class="cus-icon">
            {{ user.name ? user.name.substring(0, 1) : "UC" }}
          </div>
          <div>
            <p>{{ user.name ? user.name : "Unnamed customer" }}</p>
            <p class="message-con">{{ lastMessage }}</p>
          </div>
        </div>
        <div class="right">
          <p>1m</p>
          <p>
            <span v-show="noReadNum != 0" class="message-num">{{
              noReadNum
            }}</span>
          </p>
        </div>

        <!-- 关闭按钮 -->
        <div class="mask">
          <!-- 在线关闭 -->
          <div class="closeX" @click.stop="closeClick">
            <p  v-if="showClose">
              <i class="el-icon-close"></i>
            </p>
          </div>
          <div class="close-btn" v-if="!showClose">
            <p @click.stop="showClose = true">取消</p>
            <p @click.stop="closeArchives(chat)">
              {{ chatTypeProp == "supervising" ? "停止监听" : "关闭并存档" }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as chatApi from "@/views/liveChat/chatApi.js";
export default {
  name: "ChatInfoThumb",
  props: {
    chatProp: {
      type: Object,
      default: () => {
        return {};
      },
    },
    chatIDProp: {
      type: String,
      default: "",
    },
    chatTypeProp: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      chat: this.chatProp,
      chatID: this.chatIDProp,
      chatType: this.chatTypeProp,
      showClose: true,
      agentInfo: JSON.parse(localStorage.getItem("agentInfo")),
      lastMessage: "", //最后一次消息
      noReadNum: 0, //未读消息条数
    };
  },
  watch: {
    chatProp: {
      handler(newValue) {
        this.chat = newValue;
        this.lastMessage = newValue?.last_event_per_type
          ? newValue.last_event_per_type.message.event.text
          : newValue.thread.events[newValue.thread.events.length - 1].text;
      },
      deep: true,
      immediate: true,
    },
    chatIDProp: {
      handler(newValue) {
        this.chatID = newValue;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    cusUserID: {
      set(value) {
        this.cusUserID = value;
      },
      get() {
        let cusUserID;
        for (let i = 0; i < this.chat.users.length; i++) {
          if (this.chat.users[i].type == "customer") {
            cusUserID = this.chat.users[i].id;
          }
        }
        return cusUserID;
      },
    },
  },
  mounted() {
    // 监听设置 最后一条消息及未读条数
    this.$bus.$on("lastMessage", (chat_id, noReadNum, lastMessage) => {
      if (chat_id == this.chat.id) {
        this.noReadNum = noReadNum;
        this.lastMessage = lastMessage;
      }
    });
  },
  destroyed() {
    this.$bus.$off("lastMessage");
  },
  methods: {
    openChat(chatID, userID) {
      this.noReadNum = 0;
      this.$emit(
        "openChat",
        chatID,
        userID,
        this.chat?.state ? this.chat.state : this.chatType,
        this.index
      );
    },
    // 关闭会话
    async closeArchives(chat) {
      this.showClose = false;
      if (this.chatTypeProp == "supervising") {
        // 关闭监督
        let regExp = new RegExp(`${this.agentInfo.id},?`, "g");
        const param = {
          id: chat.id,
          properties: {
            supervising: {
              agent_ids: chat.properties.supervising.agent_ids.replaceAll(
                regExp,
                ""
              ),
            },
          },
        };
        const data = await chatApi.chat_properties_updated(param);
        if (data?.error) {
          //alert("报错", data);
          return;
        } else {
          this.$emit(
            "deleteChat",
            chat.id,
            this.cusUserID,
            this.chatType,
            this.index
          );
        }
      } else {
        // 关闭聊天
        const data = await chatApi.deactivate_chat({
          id: chat.id,
        });
        if (data?.error) {
          //alert("error", data.error);
        } else {
          this.$emit(
            "deleteChat",
            chat.id,
            this.cusUserID,
            this.chatType,
            this.index
          );
        }
      }
    },
    closeClick() {
      if (this.chat.state == "close" || this.chat.state == "banned") {
        this.$emit(
          "deleteChat",
          this.chat.id,
          this.cusUserID,
          this.chatType,
          this.index
        );
      } else {
        this.showClose =false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-list-content {
  // margin-bottom: 12px;
  .chat-checked {
    background: rgb(225, 233, 236) !important;
    border-left: 5px solid rgb(67, 132, 245) !important;
    &:hover {
      border-left: 5px solid rgb(67, 132, 245) !important;
    }
  }
  .chat-list-content-item {
    &:hover {
      background: rgb(225, 233, 236);
      border-color: rgb(225, 233, 236);
      .mask {
        display: block;
      }
      .close {
        display: block;
      }
    }
    padding: 5px 20px 5px 15px;
    border-left: 5px solid rgb(243, 247, 249);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 5px;
      color: #666666;
      .message-con {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 170px;
      }
      p {
        height: 19px;
        line-height: 19px;
      }
      .cus-icon {
        width: 40px;
        height: 40px;
        background: rgb(57, 76, 130);
        color: #fff;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
      }
    }
    .right {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-direction: column;
      p {
        height: 19px;
        line-height: 19px;
        display: flex;
        align-items: center;
      }
      .message-num {
        width: 12px;
        height: 12px;
        background: #b9070c;
        border-radius: 15px;
        font-size: 12px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .mask {
      background: rgba($color: #000000, $alpha: 0.7);
      position: absolute;
      width: calc(100% + 5px);
      height: 100%;
      left: -5px;
      top: 0px;
      top: 0px;
      display: none;
      .closeX {
        position: absolute;
        right: 5px;
        top: 0px;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        .el-icon-close{
          
        }
      }
      .close-btn {
        color: #fff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        p:nth-of-type(2) {
          color: red;
        }
      }
    }
  }
  .close-item {
    opacity: 0.5;
  }
}
</style>