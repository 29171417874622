<template>
  <div>
    <div
      :class="{
        'cus-message': !isAgentMes,
        'supervising-message': message.recipients == 'agents',
        'agent-message': isAgentMes,
      }"
      v-if="message.type == 'message'"
    >
      {{ message.text }}
    </div>
    <!-- 文件 -->
    <div v-else-if="message.type == 'file'">
      <!-- 图片 -->
      <el-image
        style="width: 100%; height: auto"
        :src="message.url"
        :preview-src-list="[message.url]"
        v-if="message.content_type.includes('image')"
      >
      </el-image>
      <!-- 视频 -->
      <div
        :class="{ 'video-message': true, 'cus-message': !isAgentMes }"
        v-else-if="message.content_type.includes('video')"
      >
        <a :href="message.url" target="_blank">{{ message.name }}</a>
      </div>
      <!-- 文件 -->
      <div v-else>{{ message.name }}</div>
    </div>
    <!-- 表单类型文案 -->
    <form-message
      v-else-if="message.type == 'filled_form'"
      :fields="message.fields"
      :formType="message.form_type"
    ></form-message>
    <!-- 卡片 -->
    <div class="card" v-else-if="message.type == 'rich_message'">
      <div>
        <img
          :src="message.elements[0].image.url"
          :alt="message.elements[0].image.alternative_text"
          width="100%"
          height="100%"
        />
      </div>
      <div class="title">{{ message.elements[0].title }}</div>
      <div class="btns">
        <div
          class="btn"
          v-for="(item, index) in message.elements[0].buttons"
          :key="index"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMessage from "./FormMessage";
export default {
  name: "Message",
  components: {
    formMessage,
  },
  props: {
    messageProp: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isAgentMes: {
      type: Boolean,
    },
  },
  data() {
    return {
      message: this.messageProp,
    };
  },
};
</script>

<style lang="scss" scoped>
.supervising-message {
  background-color: rgba(66, 77, 87, 0.8);
  color: #fff;
}
.agent-message {
  background: #427fe1;
  color: #fff;
  padding: 8px 20px;
  border-radius: 10px 0px 10px 10px;
  word-break: break-word;
  line-height: 22px;
}
.cus-message {
  background: #f2f3f4;
  color: #666;
  padding: 8px 20px;
  border-radius: 0px 10px 10px 10px;
  word-break: break-word;
  line-height: 22px;
}
.video-message {
  a {
    cursor: pointer;
    color: #2d6cfc;
    &:hover {
      text-decoration: underline;
    }
  }
}
.card {
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background: rgb(242, 243, 244);
  .title{
    padding:15px 10px;
    font-size:12px;
    line-height: 20px;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    color: rgb(141, 155, 169);
  }
}
</style>