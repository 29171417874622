<template>
  <div>
    <el-dialog
      :title="dialogObj.title"
      :visible.sync="dialogVisible"
      :class="{
        'blue-head': dialogObj.type != 'finish',
        'normal-head': dialogObj.type == 'finish',
      }"
      width="30%"
      :before-close="handleClose"
    >
      <!-- 转移聊天 -->
      <div class="content" v-if="dialogObj.type == 'transfer'">
        <el-input
          class="search-input"
          v-model="searchWord"
          placeholder="搜索"
          suffix-icon="el-icon-search"
        ></el-input>
        <ul>
          <li
            v-for="(item, index) in transferList"
            :key="index"
            @click="clickTransfer(item)"
            :class="{ clicked: item.agent_id == transfer }"
          >
            <span>{{ item.agent_id }}</span>
            <span>{{ item.total_active_chats>0?item.total_active_chats :'no'}} active chats</span>
          </li>
        </ul>
      </div>
      <!-- 禁止此访客 -->
      <div class="content" v-else-if="dialogObj.type == 'ban'">
        <p>时长</p>
        <el-select v-model="banTime" placeholder="请选择">
          <el-option
            v-for="item in timeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <p>
          <span class="notice">注意：</span
          >被禁止的客户不会看到您的聊天小部件，不会出现在您的流量
          列表中，也不会收到有针对性的消息。
        </p>
      </div>
      <!-- 结束聊天 -->
      <div class="finish-content" v-else-if="dialogObj.type == 'finish'">
        <p>
          <svg class="icon" width="30px" height="30px">
            <use href="#icon-tingzhi"></use>
          </svg>
        </p>
        <p>停止这个聊天？</p>

        <p>
          您确定要停止与<strong>{{ cusInfo.name }}</strong
          >聊天吗？
        </p>
      </div>
        <!-- 停止监督 -->
      <div class="finish-content" v-else-if="dialogObj.type == 'supervising'">
        <p>
          <svg class="icon" width="30px" height="30px">
            <use href="#icon-tingzhi"></use>
          </svg>
        </p>
        <p>停止监督这个聊天？</p>
        <p>
          您确定要停止监督这个聊天吗？
        </p>
      </div>
      <span
        slot="footer"
        :class="{
          'dialog-footer': true,
          'finish-footer': dialogObj.type == 'finish' || dialogObj.type == 'supervising',
        }"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          :type="
            dialogObj.type == 'ban' || dialogObj.type == 'finish'  || dialogObj.type == 'supervising'
              ? 'danger'
              : 'primary'
          "
          @click="confirmDialog"
          >{{ dialogObj.btnText }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as chatApi from "@/views/liveChat/chatApi.js";
export default {
  name: "chatDialog",
  props: {
    dialogVisibleProp: {
      type: Boolean,
      default: false,
    },
    cusListProp: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dialogObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    chatID: {
      type: String,
      default: "",
    },
    cusInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    dialogVisibleProp(newValue) {
      this.dialogVisible = newValue;
    },
    dialogObj(newValue) {
      // 如果弹框类型为转移客户则调用获取转移接口
      if (newValue.type == "transfer") {
        this.initTransfer();
      }
    },
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleProp,
      cusList: this.cusListProp,
      timeOptions: [
        {
          value: 1,
          label: "1天",
        },
        {
          value: 3,
          label: "3天",
        },
        {
          value: 7,
          label: "7天",
        },
        {
          value: 30,
          label: "30天",
        },
        {
          value: 180,
          label: "6个月",
        },
        {
          value: 365,
          label: "1年",
        },
      ], //禁用时长下拉框
      banTime: 1, //禁用时间
      transferList: [],
      transfer: "", //被选中的转移项
      agentInfo: JSON.parse (localStorage.getItem('agentInfo'))
    };
  },

  mounted() {},
  methods: {
    confirmDialog() {
      this.$emit(
        "confirmDialog",
        this.dialogObj.type,
        this.banTime,
        this.transfer
      );
    },
    handleClose() {
      this.$emit("closeDialog");
    },
    async initTransfer() {
      if (this.dialogObj.type == "transfer") {
        const data = await chatApi.list_agents_for_transfer(this.chatID);
        for(let index in data){
          if(data[index].agent_id == this.agentInfo.id){
            data.splice(index,1)
          }
        }
        // 去掉当前坐席
        if (data.length > 0) {
          this.transferList = data;
          this.transfer = data[0].agent_id;
        } 
      }
    },
    clickTransfer(item) {
      this.transfer = item.agent_id;
    },
  },
};
</script>
<style lang="scss" scoped>
.normal-head {
  ::v-deep .el-dialog__footer {
    text-align: center;
    border: none;
    padding-bottom: 40px;
    button {
      font-size: 14px;
      padding: 8px 30p;
    }
    .el-button--danger {
      background: #f11b21;
    }
  }
  ::v-deep .el-dialog__header {
    border: none;
    i {
      font-size: 20px;
    }
  }
}

.blue-head {
  ::v-deep .el-dialog__body {
    padding: 15px 20px;
  }
  ::v-deep .el-dialog__header {
    color: #fff;
    font-size: 20px;
    background: linear-gradient(90deg, #2d6cfc, #6c98fd);
    padding: 16px 14px 16px 16px;
    .el-dialog__title {
      color: #fff;
      font-size: 20px;
    }
    i {
      font-size: 20px;
      color: #fff;
      &:hover {
        font-size: 20px;
        color: #fff;
      }
    }
  }
}
.content {
  height: 200px;
  overflow: hidden;
  position: relative;

  p {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
    &:nth-of-type(2) {
      margin-top: 20px;
      color: #666666;
      span {
        color: #333;
      }
    }
  }
  .search-input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
  ul {
    overflow: auto;
    margin-top: 40px;
    height: 160px;
    .clicked {
        background: #f2f7ff;
      }
    li {
      padding: 5px;
      margin: 5px 0px;
      span {
        display: block;
      }
      
      color: #666666;
      font-size: 14px;
      font-weight: normal;
      &:hover {
        background: hsla(222, 97%, 58%, 0.055);
        cursor: pointer;
      }
    }
  }
}
.finish-content {
  text-align: center;
  margin-top: -10px;
  p {
    .icon {
      width: 45px;
      height: 45px;
    }
    &:nth-of-type(2) {
      margin: 20px 0px 12px;
      font-size: 20px;
      color: #333;
    }
    &:nth-of-type(3) {
      font-size: 12px;
      color: #666;
      strong {
        color: #333;
      }
    }
  }
}
.finish-footer {
  text-align: center;
  border: none;
  margin-bottom: 30px;
}
</style>