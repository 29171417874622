export const calculateDate = function(showDate=true,date){
    // 昨天
    
    let nowDate = new Date() //今天
    let incomingDate = new Date(date)
    let str = ''
    let strA  = incomingDate.toLocaleString().split(' ')[1].replace('下午','PM-') 
    
    strA = strA.replace('上午','AM-') 
    let utcIncommingDate = incomingDate.toUTCString()
    if(nowDate.getDate() - incomingDate.getDate()==1){
        str = 'yesterday'+strA.split('-').reverse().join('')
    }else if (nowDate.getDate() - incomingDate.getDate()==0||!showDate){
        str = strA.split('-').reverse().join('')
            return str
    }else{
        str = utcIncommingDate.split(',')[0]+','+incomingDate.toLocaleString().split(' ')[0]+strA.split('-').reverse().join('')
    }
    return str
}